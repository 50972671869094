import React from 'reactn';
import styles from './ConductorSearch.module.scss';

// images
import Baton from "./images/baton.jpg";

export default function ConductorSearchView(props) {
	return (
		<div className={styles.feature} onClick={props.click}>
			<h3>We’re searching for a Music Director and Conductor!</h3>
			<img src={Baton} alt="" />
			<p>To learn more click here.</p>
		</div>
	)
}
